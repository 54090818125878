.imgContainer {
    width: 283px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.integration__contentImg {
    width: 283px;
    height: 149px;
    background-color: #000000;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.integration__contentImgText {
    font-family: FordAntenna;
    font-size: 20px;
    font-weight: 500;
}


@media screen and (max-device-width: 1200px) {
    .integration__content-wImg {
        width: 280px;
    }

    .imgContainer {
        height: auto;
        align-items: center;
    }

    .integration__contentImg {
        width: 135px;
        height: 80px;
    }

    .integration__img {
        width: 55px;
        height: 55px;
    }
    
    .integration__contentImgText {
        font-size: 12px;
        width: 120px;
    }
}