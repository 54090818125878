.requestBlackTicker {
    background-image: url(/public/UX/requestBlackTicker.png);
    width: 300%;
    height: 98px;
    background-color: white;
    animation: scroll 30s linear infinite
}

@media screen and (max-device-width: 1200px) {
    .requestBlackTicker {
        display: none;
    }
}