@font-face {
    font-family: Unbounded;
    src: url(/public/fonts/Unbounded-Light.ttf);
    font-weight: 300;
}
@font-face {
    font-family: Unbounded;
    src: url(/public/fonts/Unbounded-Regular.ttf);
    font-weight: 400;
}
@font-face {
    font-family: Unbounded;
    src: url(/public/fonts/Unbounded-Medium.ttf);
    font-weight: 500;
}
@font-face {
    font-family: Unbounded;
    src: url(/public/fonts/Unbounded-SemiBold.ttf);
    font-weight: 600;
}



@font-face {
    font-family: FordAntenna;
    src: url(/public/fonts/FordAntenna-Light.ttf);
    font-weight: 300;
}
@font-face {
    font-family: FordAntenna;
    src: url(/public/fonts/FordAntenna-Regular.ttf);
    font-weight: 400;
}
@font-face {
    font-family: FordAntenna;
    src: url(/public/fonts/FordAntenna-Medium.ttf);
    font-weight: 500;
}