.footer__content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    color: white;
    background-color: rgba(0, 0, 0, 1);
    background-image: url(/public/assets/footerGradient.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 230px;
    padding-top: 80px;
}

.footer__container {
    width: 1400px;
    display: flex;
    justify-content: space-between;
}

.footer__left {
    max-width: 250px;
    max-height: 100%; 
}

.footer__leftContainer {
    display: flex;
    flex-wrap: wrap;
    row-gap: 200px;
}

.footer__left-img {
    background-image: url(/public/UX/logoFooter.svg);
    background-repeat: no-repeat;
    height: 35px;
    width: 200px;
    display: block;
}

.footer__left-policy {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.footer__left-policyTitle {
    font-family: FordAntenna;
    font-size: 14px;
    font-weight: 300;
    color: white;
    text-decoration: none;
    border-bottom: 1px solid white;
}

.footer__left-policyText {
    font-family: FordAntenna;
    font-size: 12px;
    font-weight: 300;
    color: #3A3A3A;
    width: 250px;
}

.footer__right {
    max-width: 720px;
    display: flex;
    flex-wrap: wrap;
}

.footer__rightTOP {
    display: flex;
    justify-content: space-between;
    gap: 55px;
    width: 800px;
}


.footer__right-itemTitle {
    font-family: Unbounded;
    font-size: 14px;
    font-weight: 600;
}

.footer__right-itemLinks {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 22px;
}

.footer__right-itemLink {
    font-family: FordAntenna;
    font-size: 14px;
    font-weight: 300;
    text-decoration: none;
    color: white;
    width: 175px;
    border: none;
    background-color: inherit;
    padding: 0;
    text-align: left;
    cursor: pointer;
}

.footer__rightBOTTOM {
    padding-top: 75px;
    width: 620px;
}

.footer__right-QR {
    display: flex;
    justify-content: space-between;
}

.footer__right-QRItem {
    width: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    text-decoration: none;
}

.QRBlock {
    background-repeat: no-repeat;
    background-size: cover;
    height: 74px;
    width: 74px;
    background-color: white;
    border-radius: 5px;
}
.QRBlock:hover {
    box-shadow: inset 0px 0px 75px rgba(0, 0, 0, 1);
    transition: 0.2s;
}

.footer__right-QRWe {
    background-image: url(/public/UX/WeChat.svg);
}
.footer__right-QRLinked {
    background-image: url(/public/UX/Linked.svg);
}
.footer__right-QREmail {
    background-image: url(/public/UX/Email.svg);
}
.footer__right-QRWApp {
    background-image: url(/public/UX/WApp.svg);
}

.footer__right-QRTitle {
    font-family: FordAntenna;
    font-size: 14px;
    font-weight: 300;
    text-decoration: none;
    color: white;
} 

.footer__bottom {
    padding-top: 80px;
    width: 100%;
}

.footer__bottomItem {
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.footer__bottomText {
    font-family: FordAntenna;
    font-size: 14px;
    font-weight: 300;
}

.mobile_policy {
    display: none;
}

@media screen and (max-device-width: 1200px) {

    .footer__content {
        padding-top: 20px;
    }

    .footer__content-container {
        width: 1200px;
        display: flex;
        justify-content: center;
    }

    .footer__container {
        display: block;
        width: 320px;
    }

    .footer__left-img {
        margin-left: 10px;
    }

    .footer__left-policy {
        display: none;
    }

    .footer__right {
        justify-content: center;
        width: 320px;
    }

    .footer__rightTOP {
        flex-wrap: wrap;
        width: 300px;
        gap: 0;
        justify-content: center;
        row-gap: 40px;
        margin-top: 50px;
    }
    
    .footer__right-item {
        width: 50%;
    }

    .footer__right-itemTitle {
        font-size: 12px;
    }

    .footer__right-itemLink {
        font-size: 12px;
    }

    .footer__rightBOTTOM {
        display: none;
    }
    
    .footer__bottom {
        padding-top: 50px;
    }

    .footer__bottomText {
        font-size: 10px;
    }

    .mobile_policy {
        display: flex;
        width: 300px;
        gap: 20px;
        margin-top: 50px;
    }

    .footer__left-policyTitle {
        font-size: 10px;
        border-bottom: 1px solid;
        width: auto;
    }

    .footer__left-policyText {
        font-size: 10px
    }
}