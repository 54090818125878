.orderBlock-container {
    width: 654px;
    height: 150px;
    display: flex;
    align-items: center;
    border: 1px solid #000000;
    border-radius: 10px;
}

.orderBlock__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0  50px;
}

.orderBlock__content-text {
    font-family: FordAntenna;
    font-size: 18px;
    font-weight: 300;
    width: 300px;
}

.orderBlock__content-button {
    font-family: FordAntenna;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    border: none;
    background: #000000;
    width: 200px;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
}
.orderBlock__content-button:hover {
    background: linear-gradient(230deg, #FFD916, #FF1914, #6705FF);
}

@media screen and (max-device-width: 1200px) {
    .orderBlock-container {
        width: 280px;
        height: 65px;
        display: flex;
        justify-content: center;
    }

    .orderBlock__content {
        padding: 0;
        width: 260px;
    }
    
    .orderBlock__content-text {
        font-size: 10px;
        width: 125px;
    }

    .orderBlock__content-button {
        width: 130px;
        height: 40px;
        font-size: 12px;
        padding: 0;
    }
}