.burgerBox {
    /* width: 320px; */
    width: 100vw;
    height: 100vh;
    color: white;
    position: fixed;
    top: 0;
    pointer-events: none;
    transition: 0.5s;
    opacity: 0;
    position: fixed;
    right: 0;
    background-color: black;
}

.burgerBox__show {
    opacity: 1;
    pointer-events: all;
    z-index: 2;
}

.burgerBox__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.burgerBox__logo {
    display: block;
    background-image: url(/public/UI/mobileLogo.svg);
    width: 177px;
    height: 34px;
    margin-left: 20px;
}

.burgerBox__close {
    margin-right: 20px;
    background-image: url(/public/UI/burgerClose.svg);
    width: 30px;
    height: 30px;
}

.burgerBox__menu {
    margin: 60px 20px;
}

.burgerBox__menuLinks {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.burgerBox__line {
    background: #212121;
    height: 1px;
    width: 100%;
    border: none;
}

.burgerBox__drop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.burgerBox__arrow {
    background-image: url(/public/UI/burgerArrow.svg);
    width: 10px;
    height: 5px;
}
.rot {
    transition: 0.3 s;
    transform: rotate(180deg);
}

.burgerBox__dropdown {
    width: 100%;
}

.dropdownMenu {
    display: none;
}

.dropdownMenuShow {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.burgerBox__link {
    text-decoration: none;
    color: white;
    width: 100%;
    font-family: FordAntenna;
    font-size: 14px;
    font-weight: 400;
}

.title {
    font-family: Unbounded;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 25px;
}

.burgerBox__lang {
    margin: 45px 20px
}

.burgerBox__langList {
    display: flex;
    list-style: none;
    padding: 0;
    gap: 15px;
}

.lang__item {
    font-family: FordAntenna;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
    width: 35px;
    height: 30px;
    border: none;
    color: white;
    background-color: black;
    padding: 0;
}
.lang__itemActive {
    color: black;
    background-color: white;
}

.burgerBox__button {
    margin-top: 25px;
    border: 1px solid white;
    background-color: black;
    font-family: FordAntenna;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    color: white;
    width: 170px;
    height: 40px;
    padding: 0;
}