.reqField {
    background-image: url(/public/UI/reqField.svg);
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Ford Antenna;
    font-size: 8px;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: 0.32px;
    color: #000000;
    /* width: 130px */
    padding: 5px 10px 5px 13px;
}