.company__header {
    background-color: #000000;
    height: 600px;
    background-image: 
    url(/public/assets/companyLeftGradient.svg),
    url(/public/assets/companyRightGradient.svg),
    url(/public/assets/otherPageGrid.svg);
    background-repeat: no-repeat, no-repeat, repeat;
    background-position: left top, right 30px top 30px, center;
    background-size: 800px 800px, 1200px 1200px, contain;
}

.company__header-nav {
    padding-top: 30px;
    display: flex;
    justify-content: center
}

.company__header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 112px;
}

.company__header-сontent {
    background-image: url(/public/UI/star.svg);
    background-repeat: no-repeat;
    background-position: left 48% top 8%;
    width: 1200px;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 260px;
}

.company__header-containerTitle {
    font-family: FordAntenna;
    font-size: 20px;
    font-weight: 300
}

.company__header-containerText {
    font-family: Unbounded;
    font-size: 83px;
    font-weight: 600;
    width: 560px;
}

.company__header-containerCard {
    transform:  rotate(-2deg) scale(1.2);
    width: 420px;
}

.conception__content {
    width: 100%;
    height: 490px;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(/public/assets/conceptionGradient.svg);
    background-repeat: no-repeat;
    background-size: 600px;
    background-position: left 20%;
}

.conception__content-container {
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain ;
    width: 1200px;
    color: #ffffff;
    display: flex;
}

.conception-text {
    font-family: FordAntenna;
    font-size: 20px;
    font-weight: 400;
    width: 526px;
}

.conception-title {
    margin-top: 20px;
    font-family: Unbounded;
    font-size: 35px;
    font-weight: 600;
    width: 720px;
}

.conception-button {
    margin-top: 40px;
    width: 265px;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #ffffff;
    font-family: FordAntenna;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.conception-img {
    height: 350px;
    width: 560px;
}

@media screen and (max-device-width: 1200px) {

    .company__header {
        height: auto;
        background-size: cover;
        background-image: url(/public/assets/mobileBackground.svg);
    }

    .company__header-nav {
        padding-top: 20px;
        width: 100%;
        justify-content: space-between;
    }

    .company__header-container {
        margin: 50px 0;
    }

    .company__header-сontent {
        width: 320px;
        flex-wrap: wrap;
        justify-content: center;
        height: 345px;
        background-position: 260px 15px;
        background-size: 23px;
    }

    .company__header-containerTitle {
        font-size: 10px;
        text-align: center;
    }

    .company__header-containerText {
        font-size: 30px;
        width: 230px;
        text-align: center;
    }

    .company__header-containerCard {
        display: flex;
        justify-content: center;
        width: 280px;
        transform: rotate(-2deg) 
    }

    .conception__content {
        align-items: normal;
        height: auto;
    }

    .conception__content-container {
        width: 320px;
        margin: 50px 20px;
        flex-wrap: wrap;
        gap: 50px;
        justify-content: center;
    }

    .conception-img {
        width: 280px;
        height: 165px;
    }

    .conception-text {
        font-size: 10px;
        width: 280px;
    }

    .conception-title {
        font-size: 16px;
        width: 285px;
    }

    .conception-button {
        width: 140px;
        height: 40px;
        font-size: 12px;
        margin-top: 30px;
    }
}