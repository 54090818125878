.identity__header {
    background-image:
    url(/public/assets/identityLeftGradient.svg),
    url(/public/assets/identityRightGradient.svg),
    url(/public/assets/otherPageGrid.svg);
    background-repeat: no-repeat, no-repeat, repeat;
    background-color: #000000;
    background-position: left, right top, center;
    background-size: contain, 700px 1300px, contain;
    height: 600px;
}

.identityNav {
    padding-top: 30px;
}

.identity__header-container {
    color: #ffffff;
    display: flex;
    justify-content: center;
    margin-top: 112px;
}

.identity__header-content {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 260px;
}

.identity__header-title {
    font-family: FordAntenna;
    font-size: 20px;
    font-weight: 300;
}

.identity__header-text {
    font-family: Unbounded;
    font-size: 83px;
    font-weight: 600;
    width: 720px;
}

.identity__header-card {
    transform: rotate(-2deg) scale(1.2);
    width: 420px;
}

.identity__style {
    display: flex;
    justify-content: center;
    margin: 100px 0;
}

.identity-gradient {
    background: linear-gradient(122.33deg, #FFD916 -12.52%, #FF1914 39.36%, #6705FF 83.03%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.identity-title {
    font-family: Unbounded;
    font-size: 35px;
    font-weight: 600;
    width: 530px;
}

.identity-container {
    max-width: 1200px;
    display: flex;
    gap: 78px;
    margin-top: 80px;
}

.identity-text {
    font-family: FordAntenna;
    font-size: 20px;
    font-weight: 300;
    width: 630px;
}

.identity__style-Text {
    width: 630px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: flex-end;
}

.identity__style-left {
    display: flex;
    align-items: center;
}

.identity__style-right {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: flex-end;
    width: 656px;
}

.identity__dev {
    margin: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #000000;
}

.identity__dev-container {
    width: 1200px;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    flex-wrap: wrap;
}

.dev-text {
    margin-top: 50px;
}

.identity__dev-button {
    margin-top: 142px;
}

@keyframes SlideBack {
    0% {
        transform: translateX(105px);
        z-index: 0;
    }
    20% {
        transform: translateX(105px);
        z-index: 0;
    }
    50% {
        transform: translateX(10px);
    }
    80% {
        transform: translateX(105px) scale(1.163);
        z-index: 1;
    }
    100% {
        transform: translateX(105px) scale(1.163);
        z-index: 1;
    }
}

@keyframes SlideFront {
    0% {
        transform: translateX(-100px);
    }
    20% {
        transform: translateX(-100px);
    }
    50% {
        transform: translateX(20px) scale(0.865);
    }
    80% {
        transform: translateX(-80px) scale(0.865);
        z-index: 0;
    }
    100% {
        transform: translateX(-80px) scale(0.865);
        z-index: 0;
    }
}

.identity__dev-img {
    display: flex;
    align-items: center;
}

.dev-imgOne {
    position: relative;
    animation: SlideBack 5s linear infinite alternate forwards;
} 

.dev-imgTwo {
    position: relative;
    animation: SlideFront 5s linear infinite alternate forwards;
}


.identity__dev-sub {
    display: flex;
    justify-content: center;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
}

.mobile {
    display: none;
}

@media screen and (max-device-width: 1200px) {
    .identityNav {
        padding-top: 20px;
    }

    .identity__header {
        background-size: cover;
        background-image: url(/public/assets/mobileBackground.svg);
        background-position: inherit;
        height: auto;
    }

    .identity__header-container {
        margin: 50px 0;
    }

    .identity__header-content {
        flex-wrap: wrap;
        height: 345px;
        width: 320px;
        justify-content: center;
    }

    .identity__header-title {
        font-size: 10px;
        text-align: center;
    }
    
    .identity__header-text {
        font-size: 30px;
        width: 260px;
        text-align: center;
    }

    .identity__header-card {
        display: flex;
        justify-content: center;
        width: 280px;
        height: auto;
        transform: rotate(-2deg);
    }

    .identity__style {
        margin-top: 80px;
    }

    .identity-container {
        gap: 30px;
        width: 320px;
        margin: 0;
        flex-wrap: wrap-reverse;
        justify-content: center;
    }

    .identity__content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 320px;
        gap: 30px;
    }

    .identity__style-left {
        display: flex;
        justify-content: center;
    }

    .identity__style-leftIMG {
        width: 282px;
        height: 424px;
    }
    
    .identity-title {
        font-size: 20px;
        width: 283px;
    }

    .identity__style-right {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 320px;
    }

    .identity__style-Text {
        gap: 20px;
        width: 285px;
        justify-content: flex-start;
    }

    .identity-text {
        font-size: 10px;
        width: 270px;
    }

    .identity__style-rightButton {
        display: none;
    }

    .mobile {
        margin: 0;
        display: block;
    }

    .identity__dev {
        margin: 80px 0;
    }

    .identity__dev-container {
        margin-top: 80px;
        width: 320px;
        gap: 30px;
    }

    .identity__dev-img {
        height: 375px;
    }

    .dev-text {
        margin-top: 20px;
    }

    @keyframes SlideBack {
        0% {
            transform: translateX(80px);
            z-index: 0;
        }
        20% {
            transform: translateX(80px);
            z-index: 0;
        }
        50% {
            transform: translateX(10px);
        }
        80% {
            transform: translateX(80px) scale(1.163);
            z-index: 1;
        }
        100% {
            transform: translateX(80px) scale(1.163);
            z-index: 1;
        }
    }
    
    @keyframes SlideFront {
        0% {
            transform: translateX(-80px);
        }
        20% {
            transform: translateX(-80px);
        }
        50% {
            transform: translateX(10px) scale(0.865);
        }
        80% {
            transform: translateX(-80px) scale(0.865);
            z-index: 0;
        }
        100% {
            transform: translateX(-80px) scale(0.865);
            z-index: 0;
        }
    }

    .identity__dev-sub {
        flex-wrap: wrap;
    }

    .identity__dev-button {
        display: none;
    }

    .identity__dev-subHR {
        height: 1px;
        width: 100%;
    }
}