.main__header {
    min-width: 100%;
    background: linear-gradient(74.97deg, rgba(0, 0, 0, 0.83) -1.11%, rgba(0, 0, 0, 0.88) 62.15%, rgba(0, 0, 0, 0) 99.4%) #000000;
    background-image: 
    url(/public/assets/leftGradientMain.svg), 
    url(/public/assets/rightGradientMain.svg), 
    url(/public/assets/mainPageGrid.svg), 
    url(/public/UI/mainHeaderRhomb.svg);
    background-repeat: no-repeat;
    background-position: left top, right top, center, left 430px bottom 10%;
    background-size: contain, contain, 1920px, 62px;
    height: 850px;
}

.main__header-nav {
    width: 100%;
    padding-top: 30px;
    display: flex;
    justify-content: center;    
}

.main__header-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    margin-top: 50px;
}

.main__header-containerTitle {
    width: 1200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: #ffffff;
    background-image: url(/public/assets/atom.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 15% -45px;
    min-height: 255px;
}

.main__header-containerItemOne {
    font-family: Unbounded;
    font-size: 90px;
    font-weight: 600;
    text-align: center;
    height: 112px;
}
.main__header-containerItemTwo {
    font-family: Unbounded;
    font-size: 90px;
    font-weight: 600;
    text-align: center;
    background-image: url(/public/UI/sure.svg);
    background-repeat: no-repeat;
    background-position: 97%;
    min-height: 112px;
}

.main__header-cards {
    min-width: 1200px;
}

.main__header-cards_desc_container {
    display: flex;
    justify-content: center;
    margin-bottom: 75px;
}

.main__header-cards_desc {
    width: 330px;
    height: 70px;
    font-family: FordAntenna;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
    text-align: center;
}

.main__header-cards_cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 10px;
}

.main__header-cards__button {
    display: none;
}

.mainHeaderCard {
    width: 390px;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardImg {
    backdrop-filter: blur(7px);
    border-radius: 10px;
}

.cardImgHover {
    transform: rotate(-2deg);
    transition: 0.6s;
    background: linear-gradient(230deg, #FFD916, #FF1914, #6705FF);
    backdrop-filter: none;
}

.main__header-ticker{
    background-color: #000000;
}

.main__header-tickerItem {
    width: 200%;
    height: 97px;
    background-image: url(/public/UX/tickerStringSec.png);
    animation: scroll 30s linear infinite;
}

.main__header-tickerItem:after {
    background-image: url(/public/UX/tickerStringSec.png);
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

.main__content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.main__content-cards {
    margin-top: 150px;
    width: 1600px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 80px; 
}

.main__content-cardsItem {
    width: 556px;
    height: 230px;
    border: 2px solid #000000;
    border-radius: 10px;
}

.item-img {
    background-image: url(/public/UI/rhomb.svg);
    background-repeat: no-repeat;
    max-width: 30px;
    height: 30px;
}

.main__content-cardsItemOne {
    width: 556px;
    height: 230px;
    border: none;
    border-radius: 10px;
}

.main__content-cardsItemBig {
    border: 2px solid #000000;
    border-radius: 10px;
    width: 1196px;
    height: 230px;
}

.main__content-cardsItemBigContent {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.image-big {
    width: 1168px;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px
}

.main__content-bigText {
    width: 1198px;
    display: flex;
    justify-content: center;
}

.main__content-cardsItemTitle {
    font-family: Unbounded;
    font-size: 35px;
    font-weight: 600;
    padding: 30px 30px 0 30px;
    display: flex;
    justify-content: space-between;
}
.main__content-cardsItemOneTitle {
    font-family: Unbounded;
    font-size: 40px;
    font-weight: 600;
}

.main__content-cardsItemTitleBig {
    width: 1198px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Unbounded;
    font-size: 35px;
    font-weight: 600;
}

.main__content-cardsItemText {
    font-family: FordAntenna;
    font-size: 20px;
    font-weight: 300;
    width: 400px;
    padding: 41px 0 30px 30px;
}
.main__content-cardsItemOneText {
    margin-top: 20px;
    font-family: FordAntenna;
    font-size: 20px;
    font-weight: 400;
}
.main__content-cardsItemTextBig {
    text-align: center;
    width: 540px;
    font-family: FordAntenna;
    font-size: 20px;
    font-weight: 400;
}

.item-gradient {
    background: linear-gradient(122.33deg, #FFD916 -12.52%, #FF1914 39.36%, #6705FF 83.03%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: Unbounded;
    font-size: 40px;
    font-weight: 600;
}

.main__content-marketing {
    margin-top: 146px;
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.main__content-marketingContainer {
    min-width: 100%;
    display: flex;
    justify-content: center;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
}

.main__content-marketingInfo, .main__content-makertingButton {
    display: none;
}

.main__content-marketingCompany {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 750px;
    border-right: 1px solid #000000;
    border-left: none;
    height: 500px;
    text-decoration: none;
    color: #000000;
}

.main__content-marketingMusic {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 750px;
    border-right: none;
    height: 500px;
    text-decoration: none;
    color: #000000;
}

.main__content-marketingBranding {
    display: flex;
    align-items: center;
    width: 1200px;
    height: 310px;
    justify-content: center;
    text-decoration: none;
    color: #000000;
}

.main__content-marketingTitle {
    font-family: FordAntenna;
    font-size: 20px;
    font-weight: 300;
    color: #000000;
}

.main__content-marketingText {
    font-family: Unbounded;
    font-size: 64px;
    font-weight: 600;
    width: 450px;
    margin-bottom: 80px;
    color: #000000;
}

.main__content-marketingTextImg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    max-width: 100%;
}

.main__content-marketingTextBig {
    font-family: Unbounded;
    font-size: 64px;
    font-weight: 600;
    max-width: 1010px;
    color: #000000;
}

.main__content-marketingArrowContainer {
    width: 150px;
}

@keyframes Arrow {
    0% {
        background-image: url(/public/UI/arrowMarketingHover.svg);
        transform: scaleX(0.5) translateX(-100px);
        opacity: 0.3;
    }
    100% {
        background-image: url(/public/UI/arrowMarketingHover.svg);
        transform: scaleX(1);
        opacity: 1;
    }
}

.main__content-marketingArrow {
    background-image: url(/public/UI/arrowMarketing.svg);
    background-repeat: no-repeat;
    width: 80px;
    height: 15px;
    display: flex;
    align-items: center;
}

.arrows:hover .main__content-marketingArrow {
    width: 150px;
    height: 15px;
    animation: Arrow 0.5s forwards;
}

.main__content-marketingTextGradient {
    font-family: Unbounded;
    font-size: 64px;
    font-weight: 600;
    background: linear-gradient(122.33deg, #FFD916 -12.52%, #FF1914 39.36%, #6705FF 83.03%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.main__content-idea {
    height: 400px;
    background-color: #000000;
    min-width: 100%;
    background-image: url(/public/assets/ideaGradient.svg);
    background-repeat: no-repeat;
    background-position: 500px -200px;
    background-size: 1000px;
    display: flex;
    justify-content: center;
}

.main__content-ideaContainer {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main__content-ideaLeft {
    width: 580px;
    background-image: url(/public/assets/ideaLine.svg);
    background-repeat: no-repeat;
    background-size: 270px;
    background-position: 300px 30px;
}

.main__content-ideaLeftText {
    color: #ffffff;
    font-family: Unbounded;
    font-size: 40px;
    font-weight: 600;
    width: 456px;
}

.main__content-ideaRight {
    overflow: hidden;
    padding-right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main__content-ideaRightImg {
    background-image: url(/public/UI/sure2.svg);
    background-repeat: no-repeat;
    animation: SureScroll 20s linear infinite;
    width: 52px;
    height: 52px;
    position: absolute;
}

.main__content-ideaRightText {
    background-image: url(/public/assets/MainIdeaRightText.svg);
    background-repeat: no-repeat;
    background-position: center;
    animation: TextScroll 20s linear infinite;
    width: 224px;
    height: 224px;
}
.main__content-ideaRightTextENG {
    background-image: url(/public/assets/RightTextENG.svg);
    background-repeat: no-repeat;
    background-position: center;
    animation: TextScroll 20s linear infinite;
    width: 224px;
    height: 224px;
}

@keyframes TextScroll {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(-360deg);
    }
}

@keyframes SureScroll {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}


@media screen and (max-device-width: 1200px) {

    .main__header {
        height: auto;
        background-image: url(/public/assets/mobileBackground.svg);
        background-position: inherit;
        background-size: cover;
    }

    .main__header-container {
        gap: 25px;
        margin: 50px 0;
    }

    .main__header-nav {
        padding-top: 20px;
        justify-content: space-between;
    }

    .main__header-ticker {
        display: none;
    }

    .main__header-containerTitle {
        width: 320px;
        min-height: auto;
        background-image: none;
    }

    .main__header-containerItemOne{
        font-size: 32px;
        height: auto;
    }
    .main__header-containerItemTwo {
        font-size: 32px;
        min-height: auto;
        background-image: none;
    }

    .main__header-cards {
        width: 320px;
        display: flex;
        justify-content: center;
    }

    .main__header-cards_cards {
        justify-content: center;
        width: 320px;
        gap: 25px;;
        padding: 0;
    }

    .main__header-cards_desc_container {
        display: none;
    }

    .main__header-cards__button {
        display: block;
        width: 206px;
        height: 40px;
        border-radius: 100px;
        background-color: #000000;
        border: 1px solid white;
        font-family: FordAntenna;
        font-size: 12px;
        font-weight: 500;
        color: white;
        padding: 0;
    }

    .mainHeaderCard {
        width: 240px;
        height: 140px;
        order: 2;
    }

    .IC {
        margin: 0;
        order: 1;
    }

    .main__content-cards {
        margin-top: 50px;
        gap: 30px;
        width: 320px;
    }

    .main__content-cardsItemOne {
        width: 280px;
        height: auto;
    }

    .main__content-cardsItemOneTitle {
        font-size: 20px;
    }
    .item-gradient {
        font-size: 20px;
    }

    .main__content-cardsItemOneText {
        font-size: 12px;
        margin-top: 10px;
    } 
    
    .main__content-cardsItem {
        width: 280px;
        height: 125px;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
    }

    .main__content-cardsItemTitle {
        font-size: 16px;
        padding: 20px 20px 0 20px;
    }

    .main__content-cardsItemText {
        font-size: 10px;
        padding: 0 20px 20px 20px;
        width: 220px;
    }

    .item-img {
        display: none;
    }

    .main__content-cardsItemBig {
        width: 280px;
        height: 125px;
    }

    .main__content-cardsItemBigContent {
        align-content: space-between;
        gap: 0;
        height: 100%;
    }

    .main__content-cardsItemTitleBig {
        font-size: 16px;
        justify-content: start;
        padding: 20px 20px 0 20px;
    }

    .main__content-cardsItemTextBig {
        font-size: 10px;
        text-align: start;
        padding: 0 20px 20px 20px;
    }

    .image-big {
        display: none;
    }

    .main__content-marketing {
        margin-top: 80px;
    }

    .main__content-marketingContainer {
        flex-wrap: wrap;
        border-bottom: none;
    }

    .main__content-marketingContent {
        width: 280px;
    }

    .main__content-marketingTitle {
        font-size: 10px;
        margin: 50px 0 10px 0;
    }

    .main__content-marketingText, .main__content-marketingTextGradient {
        font-size: 26px;
        margin: 0;
        margin-bottom: 20px;
        width: 200px;
    }

    .main__content-marketingCompany, .main__content-marketingMusic, .main__content-marketingBranding {
        height: auto;
        border: none;
        border-bottom: 1px solid black;
        width: 1200px;
    }

    .main__content-marketingArrowContainer {
        display: none;
    }

    .main__content-marketingTextBig {
        font-size: 26px;
        margin-bottom: 20px;
    }

    .main__content-marketingTextImg {
        flex-wrap: wrap;
        gap: 0;

    }

    .main__content-marketingInfo {
        font-family: FordAntenna;
        font-size: 10px;
        font-weight: 300;
        width: 230px;
        margin-bottom: 20px;
        display: block;
    }

    .main__content-makertingButton {
        width: 140px;
        height: 40px;
        border: 1px solid black;
        border-radius: 7px;
        background-color: white;
        font-family: FordAntenna;
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000000;
        text-decoration: none;
    }

    .main__content-idea {
        width: 320px;
        height: 200px;
        align-items: center;
    }

    .main__content-ideaContainer {
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px;
        background-image: url(/public/UI/mobileIdea.svg);
        background-repeat: no-repeat;
        background-position: center;
    }

    .main__content-ideaLeft {
        display: flex;
        justify-content: center;
        order: 2;
        background-image: none;
    }

    .main__content-ideaLeftText {
        font-size: 16px;
        width: 220px;
        text-align: center;
    }

    .main__content-ideaRight {
        padding: 0;
        width: 100%;    
        order: 1;
    }

    .main__content-ideaRightText {
        display: none;
    }
    .main__content-ideaRightTextENG {
        display: none;
    }
    .main__content-ideaRightImg {
        position: relative;
        animation: none;
    }
}