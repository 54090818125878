.subBranding-component {
    display: flex;
}

.subBranding-container {
    display: flex;
    justify-content: center;
    width: 800px;
    height: 650px;
    align-items: center;
}

.subBranding-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    width: 500px;
}

.subBranding-img {
    height: 180px;
    display: flex;
    align-items: center;
}

.subBranding-title {
    font-family: Unbounded;
    font-size: 35px;
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.subBranding-text {
    font-family: FordAntenna;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    height: 150px;
}

@media screen and (max-device-width: 1200px) {

    .subBranding-container {
        width: 320px;
        height: auto;
    }
    
    .subBranding-content {
        width: 320px;
        gap: 10px;
        margin: 80px 0 50px 0;
    }

    .subBranding-img {
        height: auto;
    }

    .subBranding-imgItem {
        width: 75px;
        height: 75px;
    }

    .subBranding-title {
        font-size: 20px;
        width: 280px;
        text-align: center;
    }

    .subBranding-text {
        font-size: 10px;
        width: 276px;
        height: auto;
    }
}