.contacts__header {
    min-height: 600px;
    background: black;
    background-image: 
    url(/public/assets/contactsLeftGradient.svg), 
    url(/public/assets/contactsRightGradient.svg),
    url(/public/assets/otherPageGrid.svg);
    background-repeat: no-repeat, no-repeat, repeat;
    background-position: left top, right, center;
    background-size: 700px 1000px, contain, contain;
}

.contacts__header-nav {
    padding-top: 30px;
    display: flex;
    justify-content: center;
}

.contacts__header-container {
    margin: 70px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 94px;
    color: white;
    flex-wrap: wrap;
}

.contacts__header-img {
    background-image: url(/public/UI/contactsSure.svg);
    width: 374px;
    height: 374px;
}

.contacts__header-contactsTitle {
    font-family: Unbounded;
    font-size: 83px;
    font-weight: 600;
}

.contacts__header-contactsContainer {
    min-width: 615px;
}

.contacts__header-contactsTextContainer {
    margin-top: 50px;
    display: flex;
    gap: 50px;
}

.contacts__header-contactsBox {
    width: 281px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
}

.contacts__header-contactsTextTitle {
    font-family: Unbounded;
    font-size: 14px;
    font-weight: 600;
}

.contacts__header-contactsText {
    font-family: FordAntenna;
    font-size: 14px;
    font-weight: 300;
    text-decoration: none;
    color: #fff;
}

.conntections {
    display: flex;
    gap: 14px;
}

@media screen and (max-device-width: 1200px) {
    .contacts__header {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        background-image: url(/public/assets/mobileBackground.svg);
        background-position: inherit;
        background-size: cover;
    }
    
    .contacts__header-nav {
        padding-top: 20px;
        width: 100%;
        justify-content: space-between;
    }

    .contacts__header-container {
        flex-wrap: wrap-reverse;
        justify-content: center;
        width: 250px;
    }
    .contacts__header-contactsContainer {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        min-width: 0;
    }
    .contacts__header-img {
        width: 220px;
        height: 220px;
        background-size: contain;
    }
    .contacts__header-contactsTextContainer {
        flex-wrap: wrap;
        justify-content: center;
    }
    .contacts__header-contactsTitle {
        font-size: 30px;
    }
    .contacts__header-contactsBox {
        display: flex;
        justify-content: center;
    }
    .contacts__header-contactsTextTitle {
        font-size: 12px;
    }
    .contacts__header-contactsText {
        font-size: 10px;
    }
}