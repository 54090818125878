.otherServicesComponent {
    display: flex;
    justify-content: center;
}

.otherServices {
    background-image: url(/public/assets/otherSevicesAtom.svg);
    background-repeat: no-repeat;
    background-position: right 6% top 19%;
}

.otherServices__container {
    width: 1200px;
    margin: 100px 0;
}

.otherServices__title {
    font-family: Unbounded;
    font-size: 56px;
    font-weight: 600;
}

.otherServices__content-container {
    margin-top: 93px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.otherServices__content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 40px;
    width: 45%;
}

.otherServices__contentTitle {
    font-family: Unbounded;
    font-size: 35px;
    font-weight: 600;
}

.otherServices__contentText {
    font-family: FordAntenna;
    font-size: 20px;
    font-weight: 300;
    width: 540px;
    /* min-height: 180px; */
}

.otherServices__contentLink {
    font-family: FordAntenna;
    font-size: 18px;
    font-weight: 300;
    width: 283px;
    height: 50px;
    border: 1px solid #000000;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #000000;
}
.otherServices__contentLink:hover {
    background-color: rgba(240, 240, 240, 1);
    color: #000000;
}

@media screen and (max-device-width: 1200px) {

    .otherServicesComponent {
        border-bottom: 1px solid black;
    }

    .otherServices__container {
        margin: 80px 0;
        display: flex;
        flex-wrap: wrap ;
        justify-content: center;
        width: 320px;
    }

    .otherServices__title {
        font-size: 12px;
        width: 280px;
    }
    
    .otherServices__content-container {
        flex-wrap: wrap;
        gap: 40px;
        margin-top: 25px;
        justify-content: center;
    }

    .otherServices__content {
        width: 280px;
        gap: 20px;
    }

    .otherServices__contentTitle {
        font-size: 20px;
    }

    .otherServices__contentText {
        font-size: 10px;
    }

    .otherServices__contentLink {
        font-size: 12px;
        width: 140px;
        height: 40px;
    }
}