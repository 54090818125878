body {
    margin: 0;
    padding: 0;
}

* {
    margin: 0;
    overflow-x: hidden;
}

a:hover {
    color: rgba(189, 189, 189, 1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none; 
    -webkit-appearance: none;
    margin: 0; 
}