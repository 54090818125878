.errorComponent {
    width: 100wh;
    height: 100vh;
    background-color: black;
    background-image: url(/public/UI/grid404.svg), url(/public/UI/gradient404.svg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorContainer {
    display: flex;
    flex-wrap: wrap;
    width: 500px;
    justify-content: center;
    gap: 150px;
    position: absolute;
    bottom: 160px;
}

.errorLogo {
    display: none;
}

.errorButton {
    width: 228px;
    height: 50px;
    background-color: black;
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-family: FordAntenna;
    font-size: 16px;
    font-weight: 500;
}

@media screen and (max-device-width: 1200px) {
    .errorComponent {
        background-image: url(/public/UI/grid404.svg), url(/public/assets/contactsLeftGradient.svg);
        background-size: inherit;
        background-position: center, left top 200px;
    }

    .errorContainer {
        position: relative;
        bottom: auto;
    }

    .errorLogo {
        display: block;
    }

    .errorImg {
        width: 206px;
        height: 96px;
    }

    .errorButton {
        border: 1px solid white;
    }
} 