.musicArtists__header {
    width: 100%;
    height: 600px;
    background-image: 
    url(/public/assets/musicArtistleftGradient.svg),
    url(/public/assets/musicArtistRightGradient.svg),
    url(/public/assets/otherPageGrid.svg);
    background-repeat: no-repeat, no-repeat, repeat;
    background-color: #000000;
    background-position: left top, right, center;
}

.musicArtists__nav {
    padding-top: 30px;
    height: 50px;
}

.musicArtists__header-container {
    display: flex;
    justify-content: center;
    margin-top: 112px;
    background-image: url(/public/UI/star.svg);
    background-repeat: no-repeat;
    background-position: 38% 12%;
}

.musicArtists__header-content {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    height: 260px;
}

.musicArtists__header-contentContainer {
    width: 550px;
}

.musicArtists__title {
    font-family: FordAntenna;
    font-size: 20px;
    font-weight: 300;
}

.musicArtists__text {
    font-family: Unbounded;
    font-size: 83px;
    font-weight: 600;
}

.musicArtists__card {
    transform: rotate(-2deg) scale(1.2);
    width: 420px;
}

@media screen and (max-device-width: 1200px) {

    .musicArtists__nav {
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .musicArtists__header {
        background-size: cover;
        background-image: url(/public/assets/mobileBackground.svg);
        height: auto;
    }

    .musicArtists__header-container {
        margin: 50px 0;
        background-position: 78% 18%;
        background-size: 23px;
        height: 345px;
    }

    .musicArtists__header-content {
        flex-wrap: wrap;
        justify-content: center;
        height: auto;
        width: 320px;
    }

    .musicArtists__header-contentContainer {
        width: 320px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center; 
    }

    .musicArtists__title {
        font-size: 10px;
        width: 100%;
    }

    .musicArtists__text {
        font-size: 30px;
        width: 100%;
    }

    .musicArtists__card {
        width: 280px;
        transform: rotate(-2deg);
        display: flex;
        justify-content: center;
    }
    
    
}