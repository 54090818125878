.requestWhiteTicker {
    background-image: url(/public/UX/requestWhiteTicker.png );
    width: 300%;
    height: 97px;
    background-color: black;
    animation: scroll 30s linear infinite
}

@media screen and (max-device-width: 1200px) {
    .requestWhiteTicker {
        display: none;
    }
}