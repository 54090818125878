* input {
    outline:none
}
input::placeholder {
    color: #A4A4A4;
}

.form {
    width: 100%;
    height: 850px;
    display: flex;
    align-items: center;
}

.form__item {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 70px;
    padding: 0 auto 0 auto;
}

.form__left {
    width: 834px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.form__left-text {
    font-family: Unbounded;
    font-size: 35px;
    font-weight: 600;
}
.form__left-textGradient {
    background: linear-gradient(149.4deg, #FFD916 7.88%, #FF1914 47.61%, #6705FF 92.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.inputs-form {
    display: flex;
    align-items: center;
    width: 100%;
}
.inputs-labelLeft {
    font-family: Unbounded;
    font-size: 16px;
    font-weight: 300;
    min-width: 76px;
    position: relative;
    bottom: 2px;
}

.form__left-inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    width: 835px;
}

.form__left-input {
    width: 100%;
    height: 40px;
    border: none;
    padding: 0;
    font-family: FordAntenna;
    font-size: 16px;
    font-weight: 300;
}::placeholder {
    font-family: FordAntenna;
    font-size: 16px;
    font-weight: 300;
    color: black;
}

.required {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
}
.requiredItem {
    font-family: FordAntenna;
    font-size: 16px;
    font-weight: 300;
    color: #A4A4A4;
    position: absolute;
    display: flex;
    gap: 10px;
    align-items: center;
}

.validItem {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.form__left-inputCheckbox {
    gap: 10px;
    display: flex;
    align-items: center;
}

.form__left-inputCheck {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
}

.label-checkbox {
    font-family: FordAntenna;
    font-size: 16px;
    font-weight: 300;
}

.label-policy {
    text-decoration: underline;
    text-underline-offset: 3px;
    color: #000000;
}

.form__right {
    max-width: 295px;
}

.form__right-container {
    min-width: 295px;
}

.form__right-img {
    display: flex;
    justify-content: center;
}

.form__right-imgItem {
    background-image: url(/public/UI/sure3.svg);
    width: 205px;
    height: 205px;
}

.inputs-labelRight {
    font-family: Unbounded;
    font-size: 16px;
    font-weight: 300;
    min-width: 53px;
    position: relative;
    bottom: 2px;
}

.form__right-input {
    width: 242px;
    height: 40px;
    border: none;
    border-bottom: 1px solid #000000;
    padding: 0;
    font-family: Unbounded;
    font-size: 14px;
    font-weight: 500;
    color: #A4A4A4;
    background-color: white;
}

.input__right-to {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.form__right-upSub{
    margin-top: 85px;
    display: flex;
    flex-wrap: wrap;
    gap: 28px;
}

.upload {
    min-width: 295px;
    height: 50px;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid black;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: FordAntenna;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;

    display: none;
}
.upload:hover {
    background-color: rgba(240, 240, 240, 1);
}

.button-clip {
    background-image: url(/public/UI/clip.svg);
    background-repeat: no-repeat;
    width: 19px;
    height: 19px;
}

.form__submit {
    min-width: 295px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: FordAntenna;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    background: #000000;
    border-radius: 10px;
    border: none;
    padding: 0;
    cursor: pointer;
}
.form__submit:hover {
    background: linear-gradient(230deg, #FFD916, #FF1914, #6705FF);
}

.mobile_checkbox {
    display: none;
}

@media screen and (max-device-width: 1200px) {
    .form__left-text {
        font-size: 20px;
        width: 240px;
        height: 65px;
    }

    .form{
        margin: 80px 0;
        height: auto;
        justify-content: center;
    }

    .form__item {
        width: 300px;
        flex-wrap: wrap;
        gap: 30px;    
    }

    .form__left {
        display: block;
        width: 280px;
    }

    .form__left-inputs {
        width: 280px;
        gap: 30px;
    }
    .form__left-inputCheckbox {
        display: none;
    }
    .inputs-labelLeft {
        font-size: 10px;
        min-width: 57px;
    }

    .form__left-input{
        font-size: 10px;
    }
    .form__left-input::placeholder {
        font-size: 10px;
    }

    .form__right-container {
        width: 280px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .form__right-img {
        display: none;
    }
    .inputs-labelRight {
        font-size: 10px;
    }
    
    .form__right-input {
        font-size: 10px;
    }
    .form__right-input::placeholder {
        font-size: 10px;
    }

    .input__right-to {
        width: 280px;
    }

    .form__right-upSub {
        margin-top: 30px;
        justify-content: center;
    }

    .form__right__buttonUpload {
        min-width: 280px;
    }

    .upload {
        font-size: 12px;
    }
    .form__submit {
        font-size: 12px;
        min-width: 280px;
    }

    .mobile_checkbox {
        display: flex;
        width: 280px;
        gap: 5px;
    }

    .label-checkbox {
        font-size: 10px;
        width: 270px;
    }
    
    .form__left-inputCheck {
        width: 10px;
        height: 10px;
    }
    
    .requiredItem {
        font-size: 10px;
    }
}