.QRmodal {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.60);
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 0.5s;
    pointer-events: none;
}

.QRactive {
    opacity: 1;
    pointer-events: all;
}

.QRmodal__container {
    background-color: #ffffff;
    width: 610px;
    height: 230px;
    display: flex;
    border-radius: 10px;
}

.QRmodal__container-left {
    width: 235px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.QRmodal__container-left_QR {
    width: 180px;
    height: 180px;
    border: 1px solid #000000;
    border-radius: 10px;
}

.QRmodal__container-right {
    display: flex;
}

.qrTitle {
    width: 100%;
}

.QRmodal__container-right_desc {
    width: 350px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.QRmodal__container-right_descContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
    color: #000000;
}

.QRmodal__container-right_descTitle {
    font-family: Unbounded;
    font-size: 29px;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.QRmodal__container-right_descText {
    font-family: FordAntenna;
    font-size: 16px;
    font-weight: 300;
}

.QRgradient {
    background: linear-gradient(122.33deg, #FFD916 -12.52%, #FF1914 39.36%, #6705FF 83.03%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.QRmodal__container-cancel {
    margin: 20px 20px 0 0;
}

.QRmodal__container-cancel_button {
    cursor: pointer;
    border: none;
    background-color: white;
    padding: 0;
}

.QRName {
    font-family: FordAntenna;
    font-size: 14px;
    font-weight: 300;
    border: 1px solid #000000;
    border-radius: 10px;
    padding: 9px 22px;
    margin-left: 10px;
}

.mobile__close {
    display: none;
}

@media screen and (max-device-width: 1200px) {
    .QRmodal__container {
        flex-wrap: wrap;
        justify-content: center;
        width: 260px;
        height: 410px;
    }

    .QRmodal__container-cancel {
        display: none;
    }

    .QRmodal__container-right_descContainer {
        width: 280px;
        justify-content: center;
    }

    .QRmodal__container-right_descTitle {
        font-size: 20px;
        width: 210px;
        text-align: center;
        justify-content: center;
        gap: 5px;
    }

    .QRName {
        font-size: 14px;
        margin: 0;
        padding: 9px 22px
    }

    .QRmodal__container-right_descText {
        display: none;
    }

    .mobile__close {
        display: block;
        height: 25px;
        border: none;
        border-bottom: 1px solid black;
        background: inherit;
        font-family: FordAntenna;
        font-size: 16px;
        font-weight: 300;
    }
}