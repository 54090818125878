.navBarComponent {
    display: flex;
    justify-content: center;
    height: 50px;
}

.navBarMobile {
    display: none;
}

.navBar {
    width: 1400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-radius: 100px;
}

.navBar__logo {
    background-image: url(/public/UX/logo.svg);
    width: 185px;
    height: 35px;
    background-repeat: no-repeat;
    margin-left: 20px;
}

.navBar__center {
    display: flex;
}

.navBar__list {
    display: flex;
    gap: 30px;
    padding: 0;
    height: 50px;
    align-items: center;
    justify-content: center;
    margin-left: 150px;
}

.navBar__list-item {
    font-family: FordAntenna;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    color: #000000;
}
.list-itemGradient {
    color: none;
    background: linear-gradient(122.33deg, #FFD916 -12.52%, #FF1914 39.36%, #6705FF 83.03%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.navBar__list__menu {
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: center;
    height: 50px;
    cursor: pointer;
}

.menuArrow {
    background-image: url(/public/UI/arrow.svg);
    background-repeat: no-repeat;
    width: 8px;
    height: 5px;
    transform: rotate(0);
    transition: all 0.5s;
}

.dropdown {
    display: flex;
    justify-content: center;
    position: absolute;
    background-color: #ffffff;
    width: 125px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    opacity: 0;
}

.dropdown__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.dropdown__content-linksContainer {
    width: 97px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.dropdown__content-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 97px;
    height: 35px;
    text-decoration: none;
    font-family: FordAntenna;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.dropdown__content-arrows{
    background-image: url(/public/UI/arrow-up-right.svg);
    width: 14px;
    height: 18px;
}

@keyframes dropdownShow {
    0% {
        transform: translateY(-20%);
    }
    100% {
        transform: translateY(0%);
    }
}

.navBar__list__menu:hover .dropdown {
    opacity: 1;
    height: 90px;
    margin-top: 140px;
    animation: dropdownShow 0.5s;
    animation-direction: alternate;
}
.navBar__list__menu:hover .menuArrow {
    transform: rotate(180deg);
    transition: 0.5s;
}
.navBar__right {
    display: flex;
    gap: 20px;
    align-items: center;
}

.navBar__listLang {
    display: flex;
    gap: 2px;
    padding: 0;
}

.navBar__listLang-item {
    font-family: FordAntenna;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    color: #000000;
    border: 1px solid white;
    width: 35px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 0;
    cursor: pointer;
}
.LangActive {
    border: 1px solid #000000;
    background-color: #000000;
    color: #ffffff;
}

.navBar__button {
    width: 228px;
    height: 50px;
    background: #060606;
    border: 1px solid;
    border-radius: 100px;
    color: #ffffff;
    font-family: FordAntenna;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}
.navBar__button:hover {
    background: linear-gradient(230deg, #FFD916, #FF1914, #6705FF);
}


@media screen and (max-device-width: 1200px) {
    .navBarComponent {
        display: none;
    }

    .navBarMobile {
        width: 320px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .navBar__mobileLogo {
        display: block;
        background-image: url(/public/UI/mobileLogo.svg);
        width: 177px;
        height: 34px;
        margin-left: 20px;
    }

    .navBar__burger {
        margin-right: 20px;
        background-image: url(/public/UI/burger.svg);
        width: 30px;
        height: 30px;
        position: fixed;
        right: 0;
        z-index: 1;
    }
}